import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function App() {
    return (
        <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
            <Typography
                align="center"
                color="text.primary"
                component="p"
            >
                TopPage
            </Typography>
            <Typography
                align="center"
                color="text.primary"
                component="p"
            >
                local: <a href="http://localhost:3000/masaaki-20230828233052">リンクはこちら</a>
            </Typography>
            <Typography
                align="center"
                color="text.primary"
                component="p"
            >
                dev: <a href="https://analysis-dev.1om1.net/masaaki-20230828233052">リンクはこちら</a>
            </Typography>
            <Typography
                align="center"
                color="text.primary"
                component="p"
            >
                prod: <a href="https://analysis.1om1.net/masaaki-20230828233052">リンクはこちら</a>
            </Typography>

        </Box>
    );
}