import { useEffect, useState } from 'react';
import { Amplify, Storage } from 'aws-amplify';
import { Configuration, OpenAIApi } from 'openai';
import Typography from '@mui/material/Typography';
import awsconfig from '../aws-exports';
import { useLocation } from 'react-router-dom';
import {
  Backdrop,
  CircularProgress,
  Box
} from '@mui/material';

Amplify.configure(awsconfig);

function AIComments(props) {
  let location = useLocation();
  const [userInput, setUserInput] = useState(props.initialUserInput);
  const [history, setHistory] = useState([]);
  const [assistantResponse, setAssistantResponse] = useState("");
  const [continueConversation, setContinueConversation] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add this

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const result = await Storage.get(`${location.pathname.slice(1).split("-")[0]}/${location.pathname.slice(1).split("-")[1]}/aicomment`, { download: true });
        const file = result.Body; // This is a Blob object
        const reader = new FileReader();
        reader.onload = function () {
          console.log(result.Body);
          const fileContent = JSON.parse(this.result);
          // console.log(fileContent.aicomment); // Logs the contents of the JSON file
          if (fileContent.aicomment) {
            setTimeout(() => {
              setAssistantResponse(fileContent.aicomment);
              setIsLoading(false); // Add this
            }, 3000); // 3 seconds delay
          }
          // if (fileContent.aicomment) {
          //     setAssistantResponse(fileContent.aicomment);
          //     setIsLoading(false); // Add this
          // }
          else {
            setContinueConversation(true);
          }

        }
        reader.readAsText(file);

      } catch (error) {
        console.log('Error fetching comments:', error);
        setContinueConversation(true);
      }
    };

    const fetchOpenAIResponse = async () => {
      const configuration = new Configuration({
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      });
      const openai = new OpenAIApi(configuration);

      const messages = [];
      for (const [input_text, completion_text] of history) {
        messages.push({ role: 'user', content: input_text });
        messages.push({ role: 'assistant', content: completion_text });
      }

      messages.push({ role: 'user', content: userInput });

      try {
        const completion = await openai.createChatCompletion({
          model: 'gpt-3.5-turbo',
          // model: 'gpt-4',
          messages: messages,
        });

        const completion_text = completion.data.choices[0].message.content;
        setAssistantResponse(completion_text);
        setIsLoading(false); // Add this
        setHistory((prevHistory) => [...prevHistory, [userInput, completion_text]]);

        // Save generated comment to S3
        const newCommentJson = { aicomment: completion_text };
        await Storage.put(`${location.pathname.slice(1).split("-")[0]}/${location.pathname.slice(1).split("-")[1]}/aicomment`, JSON.stringify(newCommentJson));
      } catch (error) {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
        } else {

          console.log(error.message);
        }
      }
    };

    fetchComments();
    if (continueConversation) {
      fetchOpenAIResponse();
    }
  }, [userInput, continueConversation]);

  return (
    <>
      <Typography variant="body" align="left" color="text.primary" paragraph style={{
        fontFamily: "'Roboto', sans-serif",
      }}
      >
      </Typography>
      {isLoading ? (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: 'grey'
          }}
          open={isLoading}  // Replace open prop with isLoading state
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="inherit" />
            <Typography variant="h6" component="div" gutterBottom>
              Analyzing with AI
            </Typography>
          </Box>

        </Backdrop >
      ) : (
        <Typography
          variant="body"
          align="left"
          color="text.secondary"
          style={{
            fontFamily: "Roboto', sans-serif",
            fontWeight: 400
          }}
        >
          {assistantResponse}
        </Typography>
      )}
    </>
  );
}

export default AIComments;