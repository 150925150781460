import React, { useEffect, useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DataContext from './DataContext';
import { useChatGPT } from './chatgpt';

const Recommend = ({ score }) => {

    const [result, setResult] = useState(null);
    const { fetchOpenAIResponse, assistantResponse } = useChatGPT();

    const { pose1, pose2, pose3, setPose1, setPose2, setPose3 } = useContext(DataContext);
    const [comment, setComment] = useState("test");
    const [pose1comment, setPose1Comment] = useState("test");
    const [pose2comment, setPose2Comment] = useState("test");
    const [pose3comment, setPose3Comment] = useState("test");
    const [firstImage, setFirstImage] = useState(null);
    const [secondImage, setSecondImage] = useState(null);
    const [thirdImage, setThirdImage] = useState(null);

    // ...[前の部分は変更なし]...
    const scores = {
        Hamstring: score.hamstring,
        SpinalLateralFlexion: (score.leftSpinalLateralFlexion + score.rightSpinalLateralFlexion) / 2,
        HipAbductor: (score.leftHipAbductor + score.rightHipAbductor) / 2,
    };

    // 最小値を持つキーを取得
    const minKey = Object.keys(scores).reduce((a, b) => (scores[a] < scores[b] ? a : b));
    const sortedEntries = Object.entries(scores).sort((a, b) => a[1] - b[1]);

    // ソートされたエントリの中から、二番目のエントリのキーを取得
    const secondMinKey = sortedEntries[1][0];

    const Hamstring = [
        "14_Adho_Mukha_Shvanasana (Downward-Facing Dog)",
        "15_Virabhadrasana_III (Warrior III)",
        "16_Parshvottanasana (Pyramid Pose)",
        "17_Uttanasana (Standing Forward Bend)",
        "18_Paschimottanasana (Seated Forward Bend)",
        "19_Navasana (Boat Pose)",
        "20_Ardha_Halasana (Half Plow Pose)",
        "21_Dandasana (Staff Pose)",
        "22_Supta_Padangusthasana (Reclining Hand-To-Big-Toe Pose)"
    ];

    const SpinalLateralFlexion = [
        "1_Chakrasana (Wheel Pose)",
        "2_Parighasana (Gate Pose)",
        "3_Parshvakonasana (Extended Side Angle Pose)",
        "4_Parivrtta_Parshvakonasana (Revolved Side Angle Pose)",
        "5_Parivrtta_Janu_Sirsasana (Revolved Head-To-Knee Pose)",
        "6_Trikonasana (Triangle Pose)",
        "7_Vasisthasana (Side Plank Pose)"
    ];

    const HipAbductor = [
        "8_Parivrtta_Janu_Sirsasana (Revolved Head-To-Knee Pose)",
        "9_Trikonasana (Triangle Pose)",
        "10_Ardha_Chandrasana (Half Moon Pose)",
        "11_Utthita_Hasta_Padangusthasana (Extended Hand-To-Big-Toe Pose)",
        "12_Upavistha_Konasana (Wide-Angle Seated Forward Bend)",
        "13_Prasarita_Padottanasana (Wide-Legged Forward Bend)"
    ];

    // 変数名をキーとして使用するためのオブジェクトを定義
    const poseLists = {
        Hamstring: Hamstring,
        SpinalLateralFlexion: SpinalLateralFlexion,
        HipAbductor: HipAbductor
    };

    // ランダムに2つのインデックスを取得
    const randomIndex1 = Math.floor(Math.random() * poseLists[minKey].length);
    let randomIndex2 = Math.floor(Math.random() * poseLists[minKey].length);
    while (randomIndex1 === randomIndex2) {
        // 同じポーズを2回選ばないように再度ランダムインデックスを生成
        randomIndex2 = Math.floor(Math.random() * poseLists[minKey].length);
    }

    // ランダムに2つのインデックスを取得
    let randomIndex3 = Math.floor(Math.random() * poseLists[secondMinKey].length);

    // // 先頭の2文字を除いて比較するヘルパー関数
    // const isSamePose = (index1, index2) => {
    //     return poseLists[minKey][index1].substring(2) === poseLists[minKey][index2].substring(2);
    // };

    // while (isSamePose(randomIndex1, randomIndex3) || isSamePose(randomIndex2, randomIndex3)) {
    //     // 同じポーズを選ばないように再度ランダムインデックスを生成
    //     randomIndex3 = Math.floor(Math.random() * poseLists[secondMinKey].length);
    // }

    // console.log(randomIndex1);
    // console.log(randomIndex2);

    const randomPose1 = poseLists[minKey][randomIndex1];
    const randomPose2 = poseLists[minKey][randomIndex2];
    const randomPose3 = poseLists[secondMinKey][randomIndex3];

    let flexibilityLevel;
    if (scores[minKey] < 3) {
        flexibilityLevel = "flexibilityLow";
    } else if (scores[minKey] < 5) {
        flexibilityLevel = "flexibilityMedium";
    } else {
        flexibilityLevel = "flexibilityHigh";
    }

    const getPoseImage = (pose, key) => ({
        default: `./${key}/${pose}/flexibilityLow.png`,
        flexibilityLow: `./${key}/${pose}/flexibilityLow.png`,
        flexibilityMedium: `./${key}/${pose}/flexibilityMedium.png`,
        flexibilityHigh: `./${key}/${pose}/flexibilityHigh.png`,
        comment: "ポーズの説明をここに記載するです"
    });

    function wait(ms) {
        const start = Date.now();
        let now = start;
        while (now - start < ms) {
            now = Date.now();
        }
    }

    useEffect(() => {
        // const isFirstRender = useRef(true);
        // if (isFirstRender.current) {
        setPose1(randomPose1.replace(/[0-9_]/g, ' ').trim());
        setPose2(randomPose2.replace(/[0-9_]/g, ' ').trim());
        setPose3(randomPose3.replace(/[0-9_]/g, ' ').trim());
        setFirstImage(getPoseImage((randomPose1.split(' (')[0]), minKey));
        setSecondImage(getPoseImage((randomPose2.split(' (')[0]), minKey));
        setThirdImage(getPoseImage((randomPose3.split(' (')[0]), secondMinKey));
        // }

        // wait(1000);  // 1秒待機
        // console.log("1秒経過しました");

        const AiRecommendCommentPose1Prompt = `
        # request
        - Please provide advice for the pose to be performed.
        
        # Role
        Yoga Instructor
        
        # Taeget
        The Yoga Student
        
        # Rules
        - Limit to 150 characters
        - Please write in sentence form.

        # Reference
        - ${randomPose1.replace(/[0-9_]/g, ' ').trim()}.
        `;

        const AiRecommendCommentPose2Prompt = `
        # request
        - Please provide advice for the pose to be performed.
        
        # Role
        Yoga Instructor
        
        # Taeget
        The Yoga Student
        
        # Rules
        - Limit to 150 characters
        - Please write in sentence form.
        
        # Reference
        - ${randomPose2.replace(/[0-9_]/g, ' ').trim()}.
        `;

        const AiRecommendCommentPose3Prompt = `
        # request
        - Please provide advice for the pose to be performed.
        
        # Role
        Yoga Instructor
        
        # Taeget
        The Yoga Student
        
        # Rules
        - Limit to 150 characters
        - Please write in sentence form.
        
        # Reference
        - ${randomPose3.replace(/[0-9_]/g, ' ').trim()}.
        `;

        const AiRecommendCommentPrompt = ` 
        # request
        - Please provide advice to the students why you recommend these three poses
        
        # Role
        Yoga Instructor
        
        # Taeget
        The Yoga Student
        
        # Rules
        - Limit to 100 characters
        - Please write in sentence form.
        - Please do not display the score.
        - Please do not display the pose names.
        
        # Reference
        ${minKey} is ${flexibilityLevel}.
        ${secondMinKey} is ${flexibilityLevel}.
        The recommended pose is ${randomPose1.replace(/[0-9_]/g, ' ').trim()}.
        The recommended pose is ${randomPose2.replace(/[0-9_]/g, ' ').trim()}.
        The recommended pose is ${randomPose3.replace(/[0-9_]/g, ' ').trim()}.
        `;

        // console.log(randomPose1)
        // console.log(randomPose2)
        // console.log(randomPose3)
        // console.log(AiRecommendCommentPose1Prompt)
        // console.log(AiRecommendCommentPose2Prompt)
        // console.log(AiRecommendCommentPose3Prompt)
        // console.log(AiRecommendCommentPrompt);

        async function fetchAIComment(prompt, setCommentFunc) {
            try {
                const response = await fetchOpenAIResponse(prompt);
                console.log("ここにchatgptの応答を記載する", response);
                setCommentFunc(response);
            } catch (error) {
                console.error("Error asking GPT-4:", error);
                setCommentFunc("Error fetching recommendation.");
            }
        }

        fetchAIComment(AiRecommendCommentPrompt, setComment);
        fetchAIComment(AiRecommendCommentPose1Prompt, setPose1Comment);
        fetchAIComment(AiRecommendCommentPose2Prompt, setPose2Comment);
        fetchAIComment(AiRecommendCommentPose3Prompt, setPose3Comment);
    }, []);


    return (
        <>
            <Grid container>
                <Grid item xs={12} md={12} style={{ marginTop: '8px' }}>
                    {/* <Grid item xs={12} md={12} style={{ marginTop: '8px', marginBottom: '8px' }}> */}
                    <Box bgcolor="white" sx={{ p: 1 }}>
                        <Typography
                            variant="h2"
                            align="center"
                            id="recommend-asana"
                            color="text.primary"
                            style={{
                                fontFamily: "'Montserrat', sans-serif",
                                fontWeight: 600
                            }}
                        >
                            Recommended Asanas For You
                        </Typography>
                        <br />
                        <Typography
                            variant="body"
                            align="left"
                            color="text.secondary"
                            paragraph
                            style={{
                                fontFamily: "Roboto', sans-serif",
                                fontWeight: 400
                            }}
                        >
                            {comment}
                        </Typography>
                    </Box>
                </Grid>

                {firstImage && ( // ここでfirstImageの存在を確認
                    <Grid item xs={12} md={6} style={{ backgroundColor: 'white' }}>
                        <Box bgcolor="white" sx={{ p: 1 }}>
                            <img src={firstImage[flexibilityLevel]} alt="Description" style={{ width: '100%', objectFit: 'contain' }} />
                            <Typography
                                variant="h6"
                                align="left"
                                color="text.primary"
                                paragraph
                                style={{
                                    fontFamily: "'Montserrat', sans-serif",
                                    fontWeight: 600
                                }}
                            >
                                {pose1}
                            </Typography>
                            <Typography
                                variant="body"
                                align="left"
                                color="text.secondary"
                                paragraph
                                style={{
                                    fontFamily: "Roboto', sans-serif",
                                    fontWeight: 400
                                }}
                            >
                                {pose1comment}
                            </Typography>
                        </Box>
                    </Grid>
                )}
                {secondImage && ( // ここでfirstImageの存在を確認
                    <Grid item xs={12} md={6} style={{ backgroundColor: 'white' }}>
                        <Box bgcolor="white" sx={{ p: 1 }}>
                            <img src={secondImage[flexibilityLevel]} alt="Description" style={{ width: '100%', objectFit: 'contain' }} />
                            <Typography
                                variant="h6"
                                align="left"
                                color="text.primary"
                                paragraph
                                style={{
                                    fontFamily: "'Montserrat', sans-serif",
                                    fontWeight: 600
                                }}
                            >
                                {pose2}
                            </Typography>
                            <Typography
                                variant="body"
                                align="left"
                                color="text.secondary"
                                style={{
                                    fontFamily: "Roboto', sans-serif",
                                    fontWeight: 400
                                }}
                            >
                                {pose2comment}
                            </Typography>
                        </Box>
                    </Grid>
                )}
                {thirdImage && (
                    <Grid item xs={12} md={6} style={{ marginBottom: '8px', backgroundColor: 'white' }}>
                        <Box bgcolor="white" sx={{ p: 1 }}>
                            <img src={thirdImage[flexibilityLevel]} alt="Description" style={{ width: '100%', objectFit: 'contain' }} />
                            <Typography
                                variant="h6"
                                align="left"
                                color="text.primary"
                                paragraph
                                style={{
                                    fontFamily: "'Montserrat', sans-serif",
                                    fontWeight: 600
                                }}
                            >
                                {pose3}
                            </Typography>
                            <Typography
                                variant="body"
                                align="left"
                                color="text.secondary"
                                style={{
                                    fontFamily: "Roboto', sans-serif",
                                    fontWeight: 400
                                }}
                            >
                                {pose3comment}
                            </Typography>
                        </Box>
                    </Grid>
                )}
                <Grid item xs={12} md={6} style={{ marginBottom: '8px', backgroundColor: 'white' }}>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Box bgcolor="white" sx={{ p: 1 }}>
                        <Typography
                            variant="h2"
                            align="center"
                            color="text.primary"
                            style={{
                                fontFamily: "'Montserrat', sans-serif",
                                fontWeight: 600
                            }} loc
                        >
                            Supervised by Nakamura Naoto
                        </Typography>
                        {/* <br /> */}
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '8px', backgroundColor: 'white' }}>
                    <Box bgcolor="white" sx={{ p: 1 }}>
                        <img src="./nakamura_naoto.png" alt="Description" style={{ width: '100%', objectFit: 'contain' }} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} style={{ marginBottom: '8px', backgroundColor: 'white' }}>
                    <Box bgcolor="white" sx={{ p: 1 }}>
                        <Typography
                            variant="h5"
                            align="left"
                            id="recommend-asana"
                            color="text.primary"
                            style={{
                                fontFamily: "'Montserrat', sans-serif",
                                fontWeight: 600
                            }}
                        >
                            Physical Therapist
                        </Typography>
                        <br />
                        <Typography
                            variant="body"
                            align="left"
                            color="text.secondary"
                            paragraph
                            style={{
                                fontFamily: "Roboto', sans-serif",
                                fontWeight: 400
                            }}
                        >
                            With 12 years of experience in the healthcare and caregiving industries as a Physical Therapist, Naoto Nakamura acquired certifications in Yoga and Pilates. He then founded studio TAKT EIGHT in Hachioji to promote and actualize preventive medicine. Currently, in addition to teaching Yoga and Pilates, he serves as the CEO of P3 Corporation and is the Executive Director of the Japan Health Foundation Association.
                        </Typography>
                        <a href="https://teacher.underthelight.jp/naoto-nakamura" target="_blank" rel="noopener noreferrer">
                            https://teacher.underthelight.jp/naoto-nakamura
                        </a>
                    </Box>
                </Grid>
            </Grid>

            {/* デバッグ用 */}
            {/* <Box bgcolor="white" sx={{ p: 1 }}>
                <Typography
                    variant="h2"
                    align="center"
                    id="recommend-asana"
                    color="text.primary"
                    style={{
                        fontFamily: "'Montserrat', sans-serif",
                        fontWeight: 600
                    }}
                >
                    Debug Mode
                </Typography>
                <br />
                <Typography
                    variant="body"
                    align="left"
                    color="text.secondary"
                    paragraph
                    style={{
                        fontFamily: "Roboto', sans-serif",
                        fontWeight: 400
                    }}
                >
                    Hip abduction flexibility is {scores.HipAbductor} out of 10.<br />
                    Spinal lateral flexibility is {scores.SpinalLateralFlexion} out of 10.<br />
                    Hamstring flexibility is {scores.Hamstring} out of 10.<br />
                    {minKey} is {flexibilityLevel}.<br />
                    {secondMinKey} is {flexibilityLevel}.<br />
                    {pose1}<br />
                    {pose2}<br />
                    {pose3}<br />
                </Typography>
            </Box> */}
        </>
    );
}

export default Recommend;
