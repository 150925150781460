import { useEffect, useState } from 'react';
import { Amplify, Storage } from 'aws-amplify';
import Typography from '@mui/material/Typography';
import awsconfig from '../aws-exports';
import { useLocation } from 'react-router-dom';
import {
  Backdrop,
  CircularProgress,
  Box
} from '@mui/material';

Amplify.configure(awsconfig);

function InstructorComments(props) {
  let location = useLocation();
  const [userInput, setUserInput] = useState(props.initialUserInput);
  const [history, setHistory] = useState([]);
  const [assistantResponse, setAssistantResponse] = useState("");
  const [continueConversation, setContinueConversation] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const result = await Storage.get(`${location.pathname.slice(1).split("-")[0]}/${location.pathname.slice(1).split("-")[1]}/instructorcomment.json`, { download: true });
        const file = result.Body; // This is a Blob object
        const reader = new FileReader();
        reader.onload = function () {
          console.log(result.Body);
          const fileContent = JSON.parse(this.result);
          console.log(fileContent.instructor.comment); // Logs the contents of the JSON file
          if (fileContent.instructor.comment) {
            setAssistantResponse(fileContent.instructor.comment);
            setIsLoading(false); // Add this
          }
          else {
            setContinueConversation(true);
          }

        }
        reader.readAsText(file);

      } catch (error) {
        console.log('Error fetching comments:', error);
        setContinueConversation(true);
      }
    };

    fetchComments();
  }, [userInput, continueConversation]);

  return (
    <>
      <Typography variant="body" align="left" color="text.primary" paragraph style={{
        fontFamily: "'Roboto', sans-serif",
      }}
      >
      </Typography>
      {isLoading ? (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: 'grey'
          }}
          open={isLoading}  // Replace open prop with isLoading state
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="inherit" />
            <Typography variant="h6" component="div" gutterBottom>
              Analyzing with AI
            </Typography>
          </Box>

        </Backdrop >
      ) : (
        <Typography variant="body" align="left" color="text.primary" paragraph style={{
          fontFamily: "'Roboto', sans-serif",
        }}
        >
          {assistantResponse}
        </Typography>
      )}
    </>
  );
}

export default InstructorComments;
