import { useState } from 'react';
import { Configuration, OpenAIApi } from 'openai';

export function useChatGPT() {
    const [userInput, setUserInput] = useState("");
    const [history, setHistory] = useState([]);
    const [assistantResponse, setAssistantResponse] = useState("");

    async function fetchOpenAIResponse(input) {
        setUserInput(input);

        const configuration = new Configuration({
            apiKey: process.env.REACT_APP_OPENAI_API_KEY,
        });
        const openai = new OpenAIApi(configuration);

        const messages = [];
        for (const [input_text, completion_text] of history) {
            messages.push({ role: 'user', content: input_text });
            messages.push({ role: 'assistant', content: completion_text });
        }

        messages.push({ role: 'user', content: input });

        try {
            const completion = await openai.createChatCompletion({
                model: 'gpt-3.5-turbo',
                // model: 'gpt-4',
                messages: messages,
            });

            const completion_text = completion.data.choices[0].message.content;
            setAssistantResponse(completion_text);
            setHistory(prevHistory => [...prevHistory, [input, completion_text]]);
            return completion_text;
        } catch (error) {
            if (error.response) {
                console.log(error.response.status);
                console.log(error.response.data);
            } else {
                console.log(error.message);
            }
            throw error;
        }
    }

    return { fetchOpenAIResponse, assistantResponse };
}
