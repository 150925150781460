import { Storage } from 'aws-amplify';
import React, { useEffect, useState, useContext } from 'react';
import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import { Radar } from 'react-chartjs-2';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AIComments from './aicomments';
import ChatGPT from './chatgpt';
import Recommend from './recommend';
import InstructorComments from './instructorcomments';
import { DataContext } from './DataContext';
import { useMediaQuery, useTheme } from '@mui/material';
import {
    Backdrop,
    CircularProgress,
} from '@mui/material';

// import Tomoko from '../images/tomoko_face.webp'


import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

Amplify.configure(awsconfig);



//日付フォーマット
function formattedDate(unixTimestamp) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log(timeZone);  // 例: "Asia/Tokyo"
    const date = new Date(unixTimestamp * 1000);
    console.log(date);
    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthName = monthNames[date.getMonth()];

    let suffix = 'th';
    if (day === 1 || day === 21 || day === 31) suffix = 'st';
    else if (day === 2 || day === 22) suffix = 'nd';
    else if (day === 3 || day === 23) suffix = 'rd';

    return `${monthName} ${day}${suffix}, ${date.getFullYear()}`;
}

//スクロール用
function smoothScrollTo(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
}

function IMAGE() {

    // 一時的なテーマを作成
    const defaultTheme = createTheme();
    const theme = createTheme({
        palette: {
            primary: {
                main: '#ff0000',
            },
            background: {
                default: '#DCDCDC',
            },
        },
        typography: {
            fontFamily: "'Montserrat', sans-serif",
            h1: {
                fontSize: '2rem',  // デフォルトのサイズ
                [defaultTheme.breakpoints.down('xs')]: {
                    fontSize: '1.5rem',
                },
                [defaultTheme.breakpoints.between('sm', 'md')]: {
                    fontSize: '1.75rem',
                },
                [defaultTheme.breakpoints.up('lg')]: {
                    fontSize: '2.5rem',
                },
            },
            h2: {
                fontSize: '1.75rem',  // デフォルトのサイズ
                [defaultTheme.breakpoints.down('xs')]: {
                    fontSize: '1.25rem',
                },
                [defaultTheme.breakpoints.between('sm', 'md')]: {
                    fontSize: '1.5rem',
                },
                [defaultTheme.breakpoints.up('lg')]: {
                    fontSize: '2rem',
                },
            },
            h3: {
                fontSize: '1.5rem',  // デフォルトのサイズ
                [defaultTheme.breakpoints.down('xs')]: {
                    fontSize: '1rem',
                },
                [defaultTheme.breakpoints.between('sm', 'md')]: {
                    fontSize: '1.25rem',
                },
                [defaultTheme.breakpoints.up('lg')]: {
                    fontSize: '1.75rem',
                },
            },
            h4: {
                fontSize: '1.25rem',
                [defaultTheme.breakpoints.down('xs')]: {
                    fontSize: '0.9rem',
                },
                [defaultTheme.breakpoints.between('sm', 'md')]: {
                    fontSize: '1.1rem',
                },
                [defaultTheme.breakpoints.up('lg')]: {
                    fontSize: '1.4rem',
                },
            },
            h5: {
                fontSize: '1.05rem',
                [defaultTheme.breakpoints.down('xs')]: {
                    fontSize: '0.85rem',
                },
                [defaultTheme.breakpoints.between('sm', 'md')]: {
                    fontSize: '0.95rem',
                },
                [defaultTheme.breakpoints.up('lg')]: {
                    fontSize: '1.2rem',
                },
            },
            h6: {
                fontSize: '0.9rem',
                [defaultTheme.breakpoints.down('xs')]: {
                    fontSize: '0.75rem',
                },
                [defaultTheme.breakpoints.between('sm', 'md')]: {
                    fontSize: '0.85rem',
                },
                [defaultTheme.breakpoints.up('lg')]: {
                    fontSize: '1rem',
                },
            },
            body1: {
                fontSize: '0.9rem',
                [defaultTheme.breakpoints.down('xs')]: {
                    fontSize: '0.8rem',
                },
                [defaultTheme.breakpoints.between('sm', 'md')]: {
                    fontSize: '0.85rem',
                },
                [defaultTheme.breakpoints.up('lg')]: {
                    fontSize: '0.95rem',
                },
            },
        },
    });
    // const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const isMd = useMediaQuery(theme.breakpoints.up('md'));
    // const { context, setContext } = useContext(DataContext);
    const { pose1, pose2, pose3, setPose1, setPose2, setPose3 } = useContext(DataContext);

    // const [isLoading, setIsLoading] = useState(true); // ローディングステートの追加
    const [promptStatus, SetPromptStatus] = useState(false);
    const [aiYogaCommentPrompt, setAiYogaCommentPrompt] = useState("");

    // 名前
    const [name, setName] = useState(null);
    const [date, setDate] = useState(null);

    //角度の最大を定義
    const minHamstringAngle = -30;
    const maxHamstringAngle = 60;

    const minSpinalLateralFlexionAngle = 0;
    const maxSpinalLateralFlexionAngle = 60;

    const minHipAbductorAngle = 0;
    const maxHipAbductorAngle = 60;

    const angles = {
        minHamstringAngle: -30,
        maxHamstringAngle: 60,
        minRightSpinalLateralFlexionAngle: 0,
        maxRightSpinalLateralFlexionAngle: 60,
        minLeftSpinalLateralFlexionAngle: 0,
        maxLeftSpinalLateralFlexionAngle: 60,
        minRightHipAbductorAngle: 0,
        maxRightHipAbductorAngle: 60,
        minLeftHipAbductorAngle: 0,
        maxLeftHipAbductorAngle: 60
    };

    let location = useLocation();
    // const [name, setName] = useState(null);
    const [age, setAge] = useState(null);
    const [imageUrl, setImageUrl] = useState([null, null, null, null, null]);

    const [score, setScore] = useState({
        'hamstring': 0,
        'leftSpinalLateralFlexion': 0,
        'rightSpinalLateralFlexion': 0,
        'rightHipAbductor': 0,
        'leftHipAbductor': 0
    });

    // const shouldRenderRecommend = Object.values(score).some(value => value !== 0);

    const [angle, setAngle] = useState(null);
    const [data, setData] = useState({
        labels: [
            ['Hamstring'],
            ['Left Spinal', 'Lateral Flexion'],
            ['Left Hip', 'Abductor'],
            ['Right hip', 'Abductor'],
            ['Right spinal', 'Lateral Flexion']
        ],
        datasets: [
            {
                label: '# SCORE',
                data: [0, 0, 0, 0, 0],
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
        ],
    });

    // const options = {
    //     scales: {
    //         r: {
    //             min: 0, // minimum value
    //             max: 10, // maximum value
    //         },
    //     },
    // };

    // const options = {
    //     scales: {
    //         r: {
    //             min: 0, // minimum value
    //             max: 10, // maximum value
    //             ticks: {
    //                 // color: '#ff0000',   // ラベルのテキストの色を赤に設定
    //                 font: {
    //                     size: 10       // ラベルのテキストのフォントサイズを16pxに設定
    //                 }
    //             },
    //             pointLabels: {
    //                 fontColor: '#ff0000',  // 軸のポイントラベルの色を黒に設定
    //                 fontSize: 16       
    //             }
    //         },
    //     },
    // };


    const options = {
        scales: {
            r: {
                min: 0,  // minimum value
                max: 10, // maximum value
                ticks: {
                    color: 'rgba(0,0,0,1)',   // ティックの色を赤に設定
                    font: {
                        size: 10       // ティックのフォントサイズを16pxに設定
                    }
                },
                angleLines: {
                    color: 'rgba(0,0,0,1)'   // 角度の線の色を赤に設定
                },
                grid: {
                    color: 'rgba(0,0,0,1)'   // グリッドの色を赤に設定
                },
                pointLabels: {
                    color: 'rgba(0,0,0,1)',   // ポイントラベルの色を赤に設定
                    font: {
                        size: 10       // ポイントラベルのフォントサイズを16pxに設定
                    }
                }
            },
        },
    };

    useEffect(() => {
        console.log(location.pathname);
        let locationPathSplit = location.pathname.slice(1).split("-");
        fetchImage(locationPathSplit);
        // fetchJsonFile(locationPathSplit);
        fetchAndProcessJsonFile(locationPathSplit);
        // const timer = setTimeout(() => {
        //     setIsLoading(false); // ページのロードが終わったらisLoadingをfalseに設定
        // }, 600000); // 2000ms(2秒)後にロード画面を非表示に設定
        // return () => clearTimeout(timer); // クリーンアップ
    }, []);

    useEffect(() => {
        if (score) {
            setData({
                labels: [
                    ['Hamstring'],
                    ['Left Spinal', 'Lateral Flexion'],
                    ['Left Hip', 'Abductor'],
                    ['Right hip', 'Abductor'],
                    ['Right spinal', 'Lateral Flexion']
                ],
                datasets: [
                    {
                        label: '# SCORE',
                        data: [
                            score.hamstring,
                            score.leftSpinalLateralFlexion,
                            score.leftHipAbductor,
                            score.rightHipAbductor,
                            score.rightSpinalLateralFlexion,

                        ],
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                        borderWidth: 1,
                    },
                ],
            });
        }
    }, [score]);

    function splitCamelCase(text) {
        return text.split(/(?=[A-Z])/).join(' ');
    }

    async function fetchImage(locationPathSplit) {
        try {
            let signedUrls = await Promise.all(
                [0, 1, 2, 3, 4].map(i =>
                    Storage.get(`${locationPathSplit[0]}/${locationPathSplit[1]}/draw_${i}.png`)
                )
            );
            setImageUrl(signedUrls);
        } catch (err) {
            console.error(err);
        }
    }

    async function fetchAndProcessJsonFile() {
        try {
            const result = await Storage.get(`${location.pathname.slice(1).split("-")[0]}/${location.pathname.slice(1).split("-")[1]}/result.json`, { download: true });
            const file = result.Body; // This is a Blob object
            const reader = new FileReader();
            reader.onload = function () {
                const fileContent = JSON.parse(this.result);
                console.log(fileContent); // Logs the contents of the JSON file
                setName(fileContent.Name);
                setDate(formattedDate(fileContent.Date));
                setAge(fileContent.Age);
                setAngle(fileContent.Angle);
                console.log(fileContent.Angle);

                let tmpscore, tmpangle;

                if (fileContent.Angle) {

                    //脊柱側屈右
                    tmpangle = parseFloat(fileContent.Angle.RightSpinalLateralFlexionAngle); // Use the specific angle you are scoring
                    console.log(tmpangle);

                    // For angles less than minAngle, assign a score of 0.
                    // For angles greater than maxAngle, assign a score of 10.
                    // For angles between minAngle and maxAngle, use a linear function to assign a score between 0 and 10.
                    if (tmpangle < minSpinalLateralFlexionAngle) {
                        tmpscore = 10;
                    } else if (tmpangle > maxSpinalLateralFlexionAngle) {
                        tmpscore = 100;
                    } else {
                        tmpscore = ((tmpangle - minSpinalLateralFlexionAngle) / (maxSpinalLateralFlexionAngle - minSpinalLateralFlexionAngle)) * 100;  // The linear function to map an angle between minAngle and maxAngle to a score between 0 and 10.
                    }

                    console.log("rightSpinalLateralFlexion:" + tmpscore);
                    const aaa = Math.round(tmpscore / 10);
                    setScore(prevScore => ({
                        ...prevScore,
                        'rightSpinalLateralFlexion': aaa  // Set the score for the specific angle
                    }));

                    //脊柱側屈左
                    tmpangle = parseFloat(fileContent.Angle.LeftSpinalLateralFlexionAngle); // Use the specific angle you are scoring

                    // For angles less than minAngle, assign a score of 0.
                    // For angles greater than maxAngle, assign a score of 100.
                    // For angles between minAngle and maxAngle, use a linear function to assign a score between 0 and 100.
                    if (tmpangle < minSpinalLateralFlexionAngle) {
                        tmpscore = 10;
                    } else if (tmpangle > maxSpinalLateralFlexionAngle) {
                        tmpscore = 100;
                    } else {
                        tmpscore = ((tmpangle - minSpinalLateralFlexionAngle) / (maxSpinalLateralFlexionAngle - minSpinalLateralFlexionAngle)) * 100;  // The linear function to map an angle between minAngle and maxAngle to a score between 0 and 100.

                    }

                    console.log("leftSpinalLateralFlexion:" + tmpscore);
                    const bbb = Math.round(tmpscore / 10);
                    setScore(prevScore => ({
                        ...prevScore,
                        'leftSpinalLateralFlexion': bbb  // Set the score for the specific angle
                    }));
                    console.log(score)

                    //股関節外転(右)

                    tmpangle = parseFloat(fileContent.Angle.RightHipAbductorAngle);
                    console.log(tmpangle);

                    if (tmpangle < minHipAbductorAngle) {
                        tmpscore = 10;
                    } else if (tmpangle > maxHipAbductorAngle) {
                        tmpscore = 100;
                    } else {
                        tmpscore = ((tmpangle - minHipAbductorAngle) / (maxHipAbductorAngle - minHipAbductorAngle)) * 100;

                    }

                    console.log("leftHipAbductor:" + tmpscore);
                    const ccc = Math.round(tmpscore / 10);
                    setScore(prevScore => ({
                        ...prevScore,
                        'rightHipAbductor': ccc
                    }));

                    //股関節外転(左)
                    tmpangle = parseFloat(fileContent.Angle.LeftHipAbductorAngle);
                    console.log(tmpangle);

                    if (tmpangle < minHipAbductorAngle) {
                        tmpscore = 10;
                    } else if (tmpangle > maxHipAbductorAngle) {
                        tmpscore = 100;
                    } else {
                        tmpscore = ((tmpangle - minHipAbductorAngle) / (maxHipAbductorAngle - minHipAbductorAngle)) * 100;
                    }

                    console.log("leftHipAbductor:" + tmpscore);
                    const ddd = Math.round(tmpscore / 10);
                    setScore(prevScore => ({
                        ...prevScore,
                        'leftHipAbductor': ddd
                    }));

                    //ハムストリング
                    // tmpangle = parseFloat(fileContent.Angle.HamstringAngle);
                    // tmpangle = 0;
                    // tmpangle = (fileContent.Angle.HamstringAngle !== null && fileContent.Angle.HamstringAngle !== "Nah") ? parseFloat(fileContent.Angle.HamstringAngle) : 0;

                    let value = fileContent.Angle.HamstringAngle;
                    tmpangle = (value !== null && value !== "Nah" && !isNaN(parseFloat(value)) && parseFloat(value) !== 0) ? parseFloat(value) : 100;



                    if (tmpangle < minHamstringAngle) {
                        tmpscore = 10;
                    } else if (tmpangle > maxHamstringAngle) {
                        tmpscore = 100;
                    } else {
                        tmpscore = ((tmpangle - minHamstringAngle) / (maxHamstringAngle - minHamstringAngle)) * 100;
                    }

                    console.log("hamstring:" + tmpscore);
                    const eee = Math.round(tmpscore / 10);
                    setScore(prevScore => ({
                        ...prevScore,
                        'hamstring': eee
                    }));

                    // # request
                    // - Please provide advice to the students why you recommend these three poses

                    // # Role
                    // Yoga Instructor

                    // # Taeget
                    // The Yoga Student
                    //評価コメント判定
                    const totalscore = (aaa + bbb + ccc + ddd + eee) * 2;
                    setAiYogaCommentPrompt(`    
                                            Please comment on the overall flexibility

                                            # Role
                                            Yoga Instructor

                                            # Taeget
                                            The Yoga Student

                                            ### Instructions
                                            Please provide suggestions for improving flexibility in yoga poses.
                                            Please be brief and summarize only the most important points.

                                            ### Requirements
                                            Limit to 400 characters

                                            ### Infomations
                                            Overall flexibility is ${totalscore} out of 100.
                                            Right Hip abduction flexibility is ${aaa} out of 10.
                                            Left Hip abduction flexibility is ${bbb} out of 10.
                                            Right SpinalLatera flexibility is ${ccc} out of 10.
                                            Left SpinalLatera flexibility is  ${ddd} out of 10.
                                            Hamstring flexibility is ${eee} out of 10.
                        `);

                    SetPromptStatus(true);
                }
            }
            reader.readAsText(file);

        } catch (error) {
            console.error("Error fetching file", error);
        }
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <Container maxWidth="md">
                    <Grid container >
                        <Grid item xs={12} md={12} style={{ marginTop: '8px', marginBottom: '8px' }}>
                            <Box bgcolor="white" sx={{ p: 1 }}>
                                <Typography
                                    variant="h1"
                                    align="center"
                                    color="text.primary"
                                    style={{
                                        fontFamily: "'Montserrat', sans-serif",
                                        fontWeight: 600
                                    }}
                                >
                                    Asana Analysis AI (β)
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box bgcolor="white" sx={{ p: 1 }}>
                                <Typography
                                    variant="h2"
                                    align="center"
                                    color="text.primary"
                                    style={{
                                        fontFamily: "'Montserrat', sans-serif",
                                        fontWeight: 600
                                    }}
                                >
                                    Your Score is{' '}
                                    <span style={{ color: 'red', fontWeight: 800 }}>
                                        {(score.rightSpinalLateralFlexion + score.leftSpinalLateralFlexion + score.rightHipAbductor + score.leftHipAbductor + score.hamstring) * 2}
                                    </span>
                                    /100!
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '8px', marginBottom: '8px', backgroundColor: 'white' }}>
                            <Box id="leftbox" bgcolor="white" sx={{ p: 1, m: 1 }}>
                                <Radar data={data} options={options} />
                                <Typography
                                    variant="h6"
                                    align="center"
                                    color="text.primary"
                                    style={{
                                        fontFamily: "'Montserrat', sans-serif",
                                        fontWeight: 600
                                    }}
                                >
                                    {name}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    align="center"
                                    color="text.primary"
                                    style={{
                                        fontFamily: "'Montserrat', sans-serif",
                                        fontWeight: 600
                                    }}
                                >
                                    {date}
                                </Typography>
                            </Box>
                        </Grid>

                        {isMd &&
                            <Grid item xs={12} md={6} style={{ marginTop: '8px', marginBottom: '8px', backgroundColor: 'white' }}>
                                <Box id="rightbox1" bgcolor="white" sx={{ p: 1, ml: 1, mb: 1, height: '70%' }}>
                                    <Typography
                                        variant="h5"
                                        align="left"
                                        color="text.primary"
                                        style={{
                                            fontFamily: "'Montserrat', sans-serif",
                                            fontWeight: 600
                                        }}
                                    >
                                        AI Yoga teacher's Comment
                                    </Typography>
                                    {promptStatus && <AIComments initialUserInput={aiYogaCommentPrompt} />}
                                </Box>
                                <Box
                                    id="rightbox2"
                                    bgcolor="white"
                                    sx={{
                                        p: 1,
                                        ml: 1,
                                        height: '28%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        align="left"
                                        color="text.primary"
                                        style={{
                                            fontFamily: "'Montserrat', sans-serif",
                                            fontWeight: 600
                                        }}
                                    >
                                        Recommended Asanas For You
                                    </Typography>
                                    <a
                                        href="#recommend-asana"
                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                        onClick={(e) => {
                                            e.preventDefault(); // cancel the default event
                                            smoothScrollTo("recommend-asana");
                                        }}
                                    >
                                        <br />
                                        <span style={{ textDecoration: 'underline', color: 'blue', fontSize: '0.9rem' }}>{pose1} ↓</span>
                                        <br />
                                        <span style={{ textDecoration: 'underline', color: 'blue', fontSize: '0.9rem' }}>{pose2} ↓</span>
                                        <br />
                                        <span style={{ textDecoration: 'underline', color: 'blue', fontSize: '0.9rem' }}>{pose3} ↓</span>
                                    </a>
                                    <br />
                                </Box>


                            </Grid>
                        }
                        {isXs &&
                            <Grid item xs={12} md={6} style={{ marginBottom: '8px' }}>
                                <Box id="rightbox1" bgcolor="white" sx={{ p: 1, mb: 1 }}>
                                    <Typography
                                        variant="h6"
                                        align="left"
                                        color="text.primary"
                                        style={{
                                            fontFamily: "'Montserrat', sans-serif",
                                            fontWeight: 600
                                        }}
                                    >
                                        AI Yoga teacher's Comment
                                    </Typography>
                                    {promptStatus && <AIComments initialUserInput={aiYogaCommentPrompt} />}
                                </Box>
                                <Box id="rightbox2" bgcolor="white" sx={{ p: 1 }}>
                                    <Typography
                                        variant="h6"
                                        align="left"
                                        color="text.primary"
                                        style={{
                                            fontFamily: "'Montserrat', sans-serif",
                                            fontWeight: 600
                                        }}
                                    >
                                        Recommends Asanas for you
                                    </Typography>
                                    <a
                                        href="#recommend-asana"
                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                        onClick={(e) => {
                                            e.preventDefault(); // デフォルトのイベントをキャンセル
                                            smoothScrollTo("recommend-asana");
                                        }}
                                    >
                                        {/* fontSize: '12px'  */}
                                        <br />
                                        <span style={{ textDecoration: 'underline', color: 'blue', fontSize: '0.85rem' }}>{pose1}↓</span>
                                        <br />
                                        <span style={{ textDecoration: 'underline', color: 'blue', fontSize: '0.85rem' }}>{pose2}↓</span>
                                        <br />
                                        <span style={{ textDecoration: 'underline', color: 'blue', fontSize: '0.85rem' }}>{pose3}↓</span>
                                    </a>
                                </Box>
                            </Grid>
                        }
                    </Grid>

                    {/* ポーズの表示 */}
                    {angle &&
                        <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
                            {/* 脊柱側屈右 */}
                            <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '8px', marginBottom: '8px' }}>
                                <Box bgcolor="white" sx={{ p: 1 }}>
                                    {imageUrl[0] && <img src={imageUrl[0]} alt="Description" style={{ width: '100%', objectFit: 'contain' }} />}
                                    <Typography variant="h5" align="left" color="text.primary" paragraph>
                                        {splitCamelCase('RightSpinalLateralFlexion')}
                                    </Typography>
                                    <Typography variant="body1" align="left" color="text.primary" paragraph style={{
                                        textDecoration: 'underline',
                                        fontFamily: "'Roboto', sans-serif",
                                    }}>
                                        Angle:{' '}
                                        <span style={{ color: 'red', fontWeight: 800, fontSize: 20 }}>
                                            {angle['RightSpinalLateralFlexionAngle'] ? parseFloat(angle['RightSpinalLateralFlexionAngle'].toFixed(0)) + "°" : "null"}
                                        </span>
                                        {' '}/ {angles['maxRightSpinalLateralFlexionAngle'] + "°"}
                                    </Typography>
                                </Box>
                            </Grid>

                            {/* 脊柱側屈左 */}
                            <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '8px', marginBottom: '8px' }}>
                                <Box bgcolor="white" sx={{ p: 1 }}>
                                    {imageUrl[1] && <img src={imageUrl[1]} alt="Description" style={{ width: '100%', objectFit: 'contain' }} />}
                                    <Typography variant="h5" align="left" color="text.primary" paragraph>
                                        {splitCamelCase('LeftSpinalLateralFlexion')}
                                    </Typography>
                                    <Typography variant="body1" align="left" color="text.primary" paragraph style={{
                                        textDecoration: 'underline',
                                        fontFamily: "'Roboto', sans-serif",
                                    }}>
                                        Angle:{' '}
                                        <span style={{ color: 'red', fontWeight: 800, fontSize: 20 }}>
                                            {angle['LeftSpinalLateralFlexionAngle'] ? parseFloat(angle['LeftSpinalLateralFlexionAngle'].toFixed(0)) + "°" : "null"}
                                        </span>
                                        {' '}/ {angles['maxLeftSpinalLateralFlexionAngle'] + "°"}
                                    </Typography>
                                </Box>
                            </Grid>

                            {/* 股関節外転右 */}
                            <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '8px', marginBottom: '8px' }}>
                                <Box bgcolor="white" sx={{ p: 1 }}>
                                    {imageUrl[1] && <img src={imageUrl[2]} alt="Description" style={{ width: '100%', objectFit: 'contain' }} />}
                                    <Typography variant="h5" align="left" color="text.primary" paragraph>
                                        {splitCamelCase('RightHipAbductor')}
                                    </Typography>
                                    <Typography variant="body1" align="left" color="text.primary" paragraph style={{
                                        textDecoration: 'underline',
                                        fontFamily: "'Roboto', sans-serif",
                                    }}>
                                        Angle:{' '}
                                        <span style={{ color: 'red', fontWeight: 800, fontSize: 20 }}>
                                            {angle['RightHipAbductorAngle'] ? parseFloat(angle['RightHipAbductorAngle'].toFixed(0)) + "°" : "null"}
                                        </span>
                                        {' '}/ {angles['maxRightHipAbductorAngle'] + "°"}
                                    </Typography>
                                </Box>
                            </Grid>

                            {/* 股関節外転左 */}
                            <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '8px', marginBottom: '8px' }}>
                                <Box bgcolor="white" sx={{ p: 1 }}>
                                    {imageUrl[3] && <img src={imageUrl[3]} alt="Description" style={{ width: '100%', objectFit: 'contain' }} />}
                                    <Typography variant="h5" align="left" color="text.primary" paragraph>
                                        {splitCamelCase('LeftHipAbductor')}
                                    </Typography>
                                    <Typography variant="body1" align="left" color="text.primary" paragraph style={{
                                        textDecoration: 'underline',
                                        fontFamily: "'Roboto', sans-serif",
                                    }}>
                                        Angle:{' '}
                                        <span style={{ color: 'red', fontWeight: 800, fontSize: 20 }}>
                                            {angle['LeftHipAbductorAngle'] ? parseFloat(angle['LeftHipAbductorAngle'].toFixed(0)) + "°" : "null"}
                                        </span>
                                        {' '}/ {angles['maxLeftHipAbductorAngle'] + "°"}
                                    </Typography>
                                </Box>
                            </Grid>

                            {/* ハムストリング */}
                            <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '8px', marginBottom: '8px' }}>
                                <Box bgcolor="white" sx={{ p: 1 }}>
                                    {imageUrl[4] && <img src={imageUrl[4]} alt="Description" style={{ width: '100%', objectFit: 'contain' }} />}
                                    <Typography variant="h5" align="left" color="text.primary" paragraph>
                                        {splitCamelCase('Hamstring')}
                                    </Typography>
                                    <Typography variant="body1" align="left" color="text.primary" paragraph style={{
                                        textDecoration: 'underline',
                                        fontFamily: "'Roboto', sans-serif",
                                    }}>
                                        Angle:{' '}
                                        <span style={{ color: 'red', fontWeight: 800, fontSize: 20 }}>
                                            {typeof angle.HamstringAngle === "number" && angle.HamstringAngle !== 0 ? parseFloat(angle.HamstringAngle.toFixed(0)) + "°" : "unmeasurable"}
                                            {/* {typeof angle.HamstringAngle === "number" ? parseFloat(angle.HamstringAngle.toFixed(0)) + "°" : "unmeasurable"} */}
                                        </span>
                                        {' '}/ {angles['maxHamstringAngle'] + "°"}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    }
                    {promptStatus && <Recommend score={score} />}
                </Container>
            </ThemeProvider >
        </>

    );
}
export default IMAGE;
