import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Top from './components/index';
import Home from './components/home';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Top />} />
        <Route path="/:aaaa" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
