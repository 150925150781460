import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Logo from '../images/1OM1_LOGO.png'
import Image from './images';
import { DataContext } from './DataContext';

import {
    Backdrop,
    CircularProgress,
    LinearProgress,
} from '@mui/material';

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://1om1.net/">
                2023, ONE OM ONE
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#ffffff',
        },
        background: {
            default: '#DCDCDC',
        },
    },
});

export default function Album() {

    const [context, setContext] = useState({
        pose1: null,
        pose2: null,
        pose3: null,
    });

    // コンテキストに状態と状態を変更するための関数をまとめる
    const contextValue = {
        ...context,
        setPose1: (value) => setContext(prev => ({ ...prev, pose1: value })),
        setPose2: (value) => setContext(prev => ({ ...prev, pose2: value })),
        setPose3: (value) => setContext(prev => ({ ...prev, pose3: value })),
    };

    return (
        <DataContext.Provider value={contextValue}>
            {/* <DataContext.Provider value={{ contextValue }}> */}
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppBar position="fixed" color="primary">
                    <Toolbar>
                        <img src={Logo} className="logo" alt="logo" width="150"></img>
                        <Box sx={{ flexGrow: 1 }} />  {/* 追加: ツールバー上の空きスペースをすべて取るためのボックス */}
                    </Toolbar>
                </AppBar>
                <main>
                    <Box
                        sx={{
                            pt: 10,
                            pb: 1,
                        }}
                    >
                        <Image />
                    </Box>
                </main>
                {/* Footer */}
                <Box sx={{ p: 1 }} component="footer">
                    <Copyright />
                </Box>
                {/* End footer */}
            </ThemeProvider>
        </DataContext.Provider>
    );
}
